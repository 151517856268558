@import url("https://fonts.googleapis.com/css2?family=Anta&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black !important;
  user-select: none;
  -webkit-user-select: none;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: Anta, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

@font-face {
  font-family: "anta";
  src: url("../src/fonts/Anta-Regular.ttf") format("truetype");
}

@supports (scrollbar-width: thin) {
  html {
    scrollbar-width: none;
  }
}
@supports not (scrollbar-width: thin) {
  html {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

html {
  background-color: #000000;
}

@supports (scroll-behavior: smooth) {
  html {
    scroll-behavior: smooth;
  }
}
@supports not (scroll-behavior: smooth) {
  html {
    scroll-behavior: auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hoverAnimation:hover {
  color: cyan;
}

.hoverBorderAnimation:hover {
  text-shadow: 0 0 1px cyan, 0 0 5px cyan, 0 0 10px cyan, 0 0 20px cyan;
}

#avatar {
  --b: 2.5px; /* the border thickness*/
  --g: 8px; /* the gap */
  --c: #4ecdc4; /* the color */

  aspect-ratio: 1;
  border-radius: 50%;
}

#avatar:hover {
  outline: var(--b) solid var(--c);
  outline-offset: var(--g);
}

main {
  max-width: 100vw;
}
